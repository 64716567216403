import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import DisclaimerBase from '@vcc-www/disclaimer';
import { DisclaimerParams, DisclaimerProps } from './Callouts.types';

const Disclaimer: React.FC<
  React.PropsWithChildren<DisclaimerParams & DisclaimerProps>
> = ({ disclaimer, disclaimerAlignmentOnMobile = 'left' }) =>
  disclaimer ? (
    <DisclaimerBase
      extend={disclaimerCSS(disclaimerAlignmentOnMobile)}
      text={disclaimer}
    />
  ) : null;

const disclaimerCSS =
  (disclaimerAlignmentOnMobile: 'left' | 'center' | 'right'): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    marginTop: baselineGrid * 4,
    untilM: {
      textAlign: disclaimerAlignmentOnMobile,
      paddingLeft: baselineGrid * 3,
      paddingRight: baselineGrid * 3,
    },
  });

export default Disclaimer;
