import React from 'react';
import { ExtendCSS, Block, Flex, Text } from 'vcc-ui';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import AnimatedReveal from '@vcc-www/animated-reveal';
import { HeadingProps, HeadingParams, MediaPresence } from './Callouts.types';

const Heading: React.FC<
  React.PropsWithChildren<HeadingProps & HeadingParams & MediaPresence>
> = ({
  heading,
  subHeading,
  headerStyle,
  showCalloutMediaInline,
  headerAlignment,
  headerPosition,
  headerPositionOnMobile,
  hasMedia,
}) => {
  const displayCalloutMedia = !hasMedia && showCalloutMediaInline;
  const hasSubHeading = Boolean(subHeading);
  const isSmallHeaderStyle = headerStyle === 'small';
  const centerHeading =
    hasMedia ||
    displayCalloutMedia ||
    !hasSubHeading ||
    headerPosition === 'center';

  return (
    <Flex extend={contentCSS}>
      {(heading || subHeading) && (
        <Flex extend={textWrapperCSS(centerHeading, hasSubHeading)}>
          <AnimatedReveal stagger={1}>
            <Text
              as="h2"
              variant={isSmallHeaderStyle ? 'hillary' : 'ootah'}
              subStyle={isSmallHeaderStyle ? 'standard' : 'emphasis'}
              extend={[
                headingWrapperCSS({
                  centerHeading,
                  isSmallHeaderStyle,
                }),
                {
                  extend: {
                    condition: !!headerAlignment,
                    style: { textAlign: headerAlignment },
                  },
                },
                {
                  extend: {
                    condition: !!headerPosition,
                    style: {
                      textAlign: headerPosition,
                    },
                  },
                },
                {
                  extend: {
                    condition: !!headerPositionOnMobile,
                    style: {
                      untilM: { textAlign: headerPositionOnMobile },
                    },
                  },
                },
              ]}
              data-autoid="callouts:heading"
              data-testid="callouts:heading"
            >
              {heading && (
                <Block
                  as="span"
                  extend={headingCSS({ isSmallHeaderStyle })}
                  data-autoid="callouts:title"
                  data-testid="callouts:title"
                >
                  {heading}
                </Block>
              )}
              {heading && subHeading && ' '}
              {subHeading}
            </Text>
          </AnimatedReveal>
        </Flex>
      )}
    </Flex>
  );
};

const contentCSS: ExtendCSS = {
  maxWidth: maxContentWidth12Columns,
  margin: '0 auto',
  alignItems: 'center',
};

const textWrapperCSS =
  (center: boolean, hasSubHeading: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    padding: hasSubHeading
      ? `0 0 ${5 * baselineGrid}px 0`
      : `0 ${2 * baselineGrid}px ${4 * baselineGrid}px ${2 * baselineGrid}px`,
    alignItems: center ? 'center' : 'inherit',
    fromM: {
      padding: center ? '0' : `0 33.3% 0 0`,
      margin: hasSubHeading
        ? `0 0 ${10 * baselineGrid}px 0`
        : `0 0 ${7 * baselineGrid}px 0`,
    },
  });

const headingCSS =
  ({ isSmallHeaderStyle }: { isSmallHeaderStyle: boolean }): ExtendCSS =>
  ({ theme: { color } }) => ({
    display: 'inline',
    color: isSmallHeaderStyle
      ? color.foreground.secondary
      : color.foreground.primary,
  });

const headingWrapperCSS =
  ({
    centerHeading,
    isSmallHeaderStyle,
  }: {
    centerHeading: boolean;
    isSmallHeaderStyle: boolean;
  }): ExtendCSS =>
  ({ theme: { color } }) => ({
    width: centerHeading ? '66.6%' : '100%',
    textAlign: centerHeading ? 'center' : 'inherit',
    color: color.foreground.secondary,
    untilM: {
      width: '100%',
      ...(isSmallHeaderStyle && {
        textAlign: 'inherit',
      }),
    },
  });

export default Heading;
