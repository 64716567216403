import { useEffect, useState } from 'react';
import { useTracker } from '@volvo-cars/tracking';
import { useVolvoCarsUrl, VolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { initializeLDClient } from '../LaunchDarklyClient';

const FLAG_KEY = 'bev-callouts-cta-gox-5';

export const useCalloutCtaInGox5MarketsFlag = ({
  isABTestingEnabled,
}: {
  isABTestingEnabled?: string;
}) => {
  const url: VolvoCarsUrl = useVolvoCarsUrl();
  const tracker = useTracker();
  const [variation, setVariation] = useState(undefined);
  const isValidMarkets =
    url.siteSlug === 'uk' ||
    url.siteSlug === 'de' ||
    url.siteSlug === 'se' ||
    url.siteSlug === 'nl' ||
    url.siteSlug === 'no';

  useEffect(() => {
    if (!isABTestingEnabled) return;
    let isMounted = true;
    (async () => {
      if (isMounted) {
        const ldClient = await initializeLDClient(url);
        if (ldClient && isValidMarkets) {
          const flag = ldClient.variation(`experiment.${FLAG_KEY}`);
          setVariation(flag);
          tracker.pushCustomDimension('featureList', `callouts=${flag}`);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [url, isValidMarkets, tracker, variation, isABTestingEnabled]);
  return variation;
};
