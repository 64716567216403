import React from 'react';
import { ExtendCSS, Block, Flex } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import Video, { hasVideoContent, VideoContentType } from '@vcc-www/video';
import { useBreakpoints } from '@vcc-www/hooks';
import { MediaProps, MediaParams, MediaPresence } from './Callouts.types';

const Media: React.FC<
  React.PropsWithChildren<MediaProps & MediaParams & MediaPresence>
> = ({
  videos,
  portraitImage,
  portraitVideoMp4,
  portraitVideoWebm,
  landscapeImage,
  landscapeVideoMp4,
  landscapeVideoWebm,
  mediaWidthDesktop,
  largePlayIcon,
  lazyLoadPoster,
  loop,
  loopVideo,
  autoplay,
  centeredPlayIcon,
  marginsOnMobile,
  hasImage,
  hasVideo,
}) => {
  const { untilM } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  loopVideo = hasVideo && (loopVideo || loop);
  const hasMedia = hasImage || hasVideo;
  if (!hasMedia) return null;

  return (
    <Flex
      extend={mediaCSS({
        marginsOnMobile: Boolean(marginsOnMobile),
        mediaWidthDesktop,
      })}
    >
      {hasImage && !hasVideo && (
        <Block extend={imageContainerCSS}>
          <Image_DEPRECATED
            alt={untilM ? portraitImage?.alt || '' : landscapeImage?.alt || ''}
            sources={{
              default: landscapeImage?.src || '',
              untilM: portraitImage?.src,
            }}
            aspectRatio={{
              default: [16, 9],
              untilM: [4, 3],
            }}
            objectFit="cover"
            loading="native-lazy"
            data-autoid="callouts:image"
            data-testid="callouts:image"
          />
        </Block>
      )}
      {hasVideo &&
        (hasVideoContent({ videos }) ? (
          <Video
            content={{ videos } as VideoContentType}
            largePlayIcon={largePlayIcon}
            lazyLoadPoster={lazyLoadPoster}
            loop={loop}
            autoplay={autoplay}
            centeredPlayIcon={centeredPlayIcon}
          />
        ) : (
          <>
            <Block extend={mediaContainerWideViewportCSS}>
              <Video
                loop={loopVideo}
                poster={landscapeImage?.src}
                srcMp4={landscapeVideoMp4?.src}
                srcWebM={landscapeVideoWebm?.src}
                data-autoid="callouts:video"
                data-testid="callouts:video"
                aspectRatio={[16, 9]}
              />
            </Block>
            <Block extend={mediaContainerNarrowViewportCSS}>
              <Video
                loop={loopVideo}
                poster={portraitImage?.src}
                srcMp4={portraitVideoMp4?.src}
                srcWebM={portraitVideoWebm?.src}
                data-autoid="callouts:video-mobile"
                data-testid="callouts:video-mobile"
                aspectRatio={[4, 3]}
              />
            </Block>
          </>
        ))}
    </Flex>
  );
};

const mediaCSS =
  ({
    marginsOnMobile,
    mediaWidthDesktop,
  }: {
    marginsOnMobile: boolean;
    mediaWidthDesktop?: number;
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    untilM: {
      paddingBottom: baselineGrid * 5,
      margin: marginsOnMobile ? '0' : `0 -${baselineGrid * 3}px`,
    },
    fromM: {
      marginBottom: baselineGrid * 2,
    },
    ...(typeof mediaWidthDesktop !== 'undefined' && {
      fromL: {
        // sitecore thinks, that `FieldType.Number` is string...
        width: Number(mediaWidthDesktop),
        marginBottom: baselineGrid * 4,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  });

const mediaContainerNarrowViewportCSS: ExtendCSS = {
  height: 0,
  paddingBottom: '75%', // enforce 4:3 ratio fix for IE11
  fromM: {
    display: 'none',
  },
};

const mediaContainerWideViewportCSS: ExtendCSS = {
  display: 'none',
  height: 0,
  paddingBottom: '56.25%', // enforce 16:9 ratio fix for IE11
  fromM: {
    display: 'block',
  },
};

const imageContainerCSS: ExtendCSS = {
  height: 0,
  paddingBottom: '75%',
  fromM: {
    paddingBottom: '56.25%',
  },
};

export default Media;
