import React from 'react';
import { ExtendCSS, Flex, useTheme } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import DisclaimerShared from '@vcc-www/disclaimer';
import {
  SpringCarouselDots,
  SpringCarouselPane,
  SpringCarouselProvider,
} from '@vcc-www/spring-carousel';
import { useTracker } from '@volvo-cars/tracking';
import { Callouts } from './Callouts.types';

export const CarouselProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const track = useTracker();
  return (
    <SpringCarouselProvider
      onChange={(event) => {
        if (event.type === 'swipe-pane') {
          track.interaction({
            eventAction: 'swipe',
            eventLabel: event.previous < event.current ? 'right' : 'left',
          });
        }
      }}
    >
      {children}
    </SpringCarouselProvider>
  );
};

export const CalloutsCarousel: React.FC<React.PropsWithChildren<Callouts>> = ({
  showAllCalloutItems,
  callouts,
}) => (
  <Flex extend={mobileImagesCSS}>
    {showAllCalloutItems ? (
      <CarouselProvider>
        <SpringCarouselPane alignment="left-cover" itemWidth={1}>
          {callouts
            .slice(0, showAllCalloutItems ? undefined : 3)
            .map(
              (callout, idx) =>
                callout.image && (
                  <Image_DEPRECATED
                    key={idx}
                    src={callout.image.src}
                    alt={callout.image.alt}
                    objectFit="cover"
                    loading="native-lazy"
                    aspectRatio={[4, 3]}
                    data-autoid={`callouts:grid:image:${idx}`}
                    extend={carouselImageCSS}
                    draggable="false"
                  />
                ),
            )}
        </SpringCarouselPane>
        <Flex extend={dotsCSS}>
          <SpringCarouselDots />
        </Flex>
      </CarouselProvider>
    ) : (
      <>
        <SpringCarouselPane alignment="left-cover" itemWidth={1}>
          {callouts
            .slice(0, 3)
            .map(
              (callout, idx) =>
                callout.image && (
                  <Image_DEPRECATED
                    key={idx}
                    src={callout.image.src}
                    alt={callout.image.alt}
                    objectFit="cover"
                    loading="native-lazy"
                    aspectRatio={[4, 3]}
                    data-autoid={`callouts:grid:image:${idx}`}
                    extend={carouselImageCSS}
                    draggable="false"
                  />
                ),
            )}
        </SpringCarouselPane>
        <Flex extend={dotsCSS}>
          <SpringCarouselDots />
        </Flex>
      </>
    )}
  </Flex>
);

export const CalloutDisclaimersCarousel: React.FC<
  React.PropsWithChildren<Callouts>
> = ({ callouts }) => {
  const { baselineGrid } = useTheme();
  return (
    <SpringCarouselPane
      alignment="left-cover"
      itemWidth={1}
      itemSpacing={3 * baselineGrid}
    >
      {callouts.slice(0, 3).map((callout, idx) => (
        <DisclaimerShared
          key={idx}
          extend={inlineDisclaimerCSS}
          text={callout.disclaimer}
        />
      ))}
    </SpringCarouselPane>
  );
};

const mobileImagesCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  paddingBottom: 2 * baselineGrid,

  fromM: {
    display: 'none',
  },
});

const dotsCSS: ExtendCSS = {
  fromM: {
    display: 'none',
  },
};

const carouselImageCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `0 0 ${baselineGrid}px 0`,
});

const inlineDisclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: 4 * baselineGrid,

  fromM: {
    display: 'none',
  },
});
