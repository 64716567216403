import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import SectionCover from '@vcc-www/page-section/SectionCover';
import { Callouts as CalloutsBase } from '@vcc-package/unique-selling-points';
import CalloutsProps from './Callouts.props';
import { IdParam } from '@vcc-www/page-section';

const Callouts: React.FC<CalloutsProps & IdParam> = ({
  marginsOnMobile,
  bottomInnerSpacing,
  bottomOuterSpacing,
  topInnerSpacing,
  topOuterSpacing,
  background,
  id,
  ...props
}) => {
  return (
    <CalloutsBase
      SectionCoverComponent={({ children }: { children?: React.ReactNode }) => (
        <SectionCover
          id={id}
          extend={sectionCSS}
          data-autoid="callouts"
          {...{
            marginsOnMobile,
            bottomInnerSpacing,
            bottomOuterSpacing,
            topInnerSpacing,
            topOuterSpacing,
            background,
          }}
        >
          {children}
        </SectionCover>
      )}
      {...props}
      marginsOnMobile={marginsOnMobile}
    />
  );
};

const sectionCSS: ExtendCSS = {
  overflow: 'hidden',
  maxWidth: 1440,
  marginLeft: 'auto',
  marginRight: 'auto',
};

export default Callouts;
