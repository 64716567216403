import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { Button } from '@vcc-www/buttons';
import { CtasParams, CtasProps, ShowAllCalloutItems } from './Callouts.types';

const Ctas: React.FC<
  React.PropsWithChildren<
    CtasProps & CtasParams & ShowAllCalloutItems & { openOverlay: () => void }
  >
> = ({
  cta,
  callouts,
  ctaLabel,
  ctaHref,
  ctaTrackingLabel,
  ctaVariant = 'outline',
  openOverlay,
  showAllCalloutItems = false,
}) => {
  const showMoreButton =
    !showAllCalloutItems && callouts?.length > 3 && !ctaHref;
  const showCta = showMoreButton || ctaHref;
  const hasCtaLink = !!cta?.href;
  const showCtaLink = showMoreButton || hasCtaLink;

  const getTrackingLabel = () => {
    let label = `${ctaLabel || cta?.text} | ${ctaHref || 'overlay'}`;
    if (ctaTrackingLabel) {
      label += ` | ${ctaTrackingLabel}`;
    }
    return label;
  };

  return (
    <>
      {showCta && !hasCtaLink && (ctaLabel || cta?.text) && (
        <Block extend={ctaContainerCSS}>
          <Button
            variant={ctaVariant}
            trackEventLabel={`${ctaLabel || cta?.text} | overlay`}
            data-autoid="callouts:learnMore"
            data-testid="callouts:ctaHref"
            onClick={ctaHref ? undefined : openOverlay}
            href={ctaHref ? ctaHref : undefined}
            trackEventAction="button|click"
            trackGA3={{
              eventAction: 'click',
              eventLabel: getTrackingLabel(),
            }}
          >
            <Block extend={ctaTextCSS}>{ctaLabel || cta?.text}</Block>
          </Button>
        </Block>
      )}
      {showCtaLink && cta && cta.href && (
        <Block extend={ctaContainerCSS}>
          <Button
            variant={ctaVariant}
            aria-label={cta.accessibilityLabel}
            href={cta.href}
            data-testid="callouts:cta"
            target={cta.target}
            trackEventLabel={
              ctaTrackingLabel
                ? `${cta.text}|${cta.href}|${ctaTrackingLabel}`
                : `${cta.text}|${cta.href}`
            }
            trackEventAction="button|click"
            trackGA3={{
              eventAction: 'click',
            }}
          >
            {cta.text}
          </Button>
        </Block>
      )}
    </>
  );
};

const ctaContainerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  maxWidth: '100%',
  marginTop: baselineGrid * 4,
});

const ctaTextCSS: ExtendCSS = {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export default Ctas;
