import React from 'react';
import { Block, ExtendCSS, Flex } from 'vcc-ui';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';

export const Container: React.FC<
  React.PropsWithChildren<{
    isMobileFullWidth?: boolean;
    isFullWidth?: boolean;
  }>
> = ({ isMobileFullWidth, isFullWidth, children }) => (
  <Block extend={innerSectionCSS(isMobileFullWidth, isFullWidth)}>
    {children}
  </Block>
);

export const ContentContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <Flex extend={contentCSS}>{children}</Flex>;

const innerSectionCSS =
  (isMobileFullWidth = false, isFullWidth = false): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    maxWidth: maxContentWidth12Columns,
    width: isFullWidth ? '100%' : `calc(100% - ${6 * baselineGrid}px)`,
    margin: '0 auto',
    ...(isMobileFullWidth && {
      untilM: {
        width: '100%',
      },
    }),
  });

const contentCSS: ExtendCSS = {
  maxWidth: maxContentWidth12Columns,
  margin: '0 auto',
  alignItems: 'center',
};
