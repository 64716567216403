import React from 'react';
import { ExtendCSS, Flex, Text, Spacer, Block } from 'vcc-ui';
import { Button, Link } from '@vcc-www/buttons';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import Disclaimer from '@vcc-www/disclaimer';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-www/markdown';
import AnimatedReveal from '@vcc-www/animated-reveal';
import ResponsiveGridHelper from '@vcc-www/responsive-grid-helper';
import { CalloutsContentProps } from './CalloutsContent.props';
import Video, { getAspectRatio } from '@vcc-www/video';
import { PopUpDisclaimer } from '@vcc-package/overlays';
import isEmpty from 'lodash/isEmpty';

const CalloutsContent: React.FC<
  React.PropsWithChildren<CalloutsContentProps>
> = ({
  callouts = [],
  autoid,
  displayImages,
  imageDensity,
  paddedItemsOnDesktop = false,
  showImagesOnMobile = true,
  titleVariant = 'regular-bold',
  descriptionVariant = 'regular',
  contentDirectionOnDesktop,
  extendGridCSS,
  extendItemCSS,
  showAll = false,
  trackingData,
  blackBoldDescription = false,
  imageClickable = false,
  showAllCalloutItems = false,
  linkToButton = false,
  classNameImg,
  classNameLink,
  stackOnMobile = false,
}) => {
  const titleStyle =
    titleVariant === 'large'
      ? 'ootah'
      : titleVariant.split('-')?.[0] === 'small'
        ? 'columbus'
        : 'hillary';
  const descriptionStyle =
    descriptionVariant === 'regular' ? 'columbus' : 'hillary';
  const isTitleEmphasized = titleVariant.split('-')?.[1] === 'bold';
  const eventCategory = trackingData?.eventCategory ?? autoid;
  return (
    <Flex extend={contentCSS}>
      <ResponsiveGridHelper
        size={paddedItemsOnDesktop ? { default: 12, fromL: 8 } : 12}
      >
        <Flex
          extend={[
            calloutGridCSS(contentDirectionOnDesktop, showAllCalloutItems),
            extendGridCSS,
          ]}
          data-autoid={`${autoid}:grid`}
        >
          {callouts
            .slice(0, showAllCalloutItems || showAll ? undefined : 3)
            .map((callout, idx) => {
              const video = callout.videoMp4?.src;
              const videoPoster = callout.videoPoster?.src;
              const popUpDisclaimerItem = callout.popUpDisclaimer?.[0];
              const imageAvailable = !!callout.image && !isEmpty(callout.image);

              return (
                <AnimatedReveal key={idx} stagger={idx}>
                  <Flex
                    extend={[
                      calloutItemCSS({
                        isMobileFullWidth: showImagesOnMobile,
                        constrainMaxWidth:
                          !!displayImages &&
                          imageAvailable &&
                          contentDirectionOnDesktop !== 'vertical',
                        showAllCalloutItems,
                      }),
                      extendItemCSS,
                    ]}
                    className={classNameImg}
                  >
                    {displayImages &&
                      imageAvailable &&
                      !video &&
                      (callout.cta?.href && imageClickable ? (
                        <Flex
                          as="a"
                          extend={imageClickCSS(imageClickable)}
                          href={callout.cta?.href}
                          data-autoid="calloutLink"
                          draggable="false"
                        >
                          <Image_DEPRECATED
                            src={callout.image.src}
                            alt={callout.image.alt}
                            objectFit="cover"
                            sizes={{ fromM: '33vw' }}
                            loading="native-lazy"
                            aspectRatio={[4, 3]}
                            data-autoid={`${autoid}:grid:image:${idx}`}
                            extend={imageCSS({
                              mobileImages: showImagesOnMobile,
                            })}
                            imageDensity={imageDensity}
                          />
                        </Flex>
                      ) : (
                        <Image_DEPRECATED
                          src={callout.image.src}
                          alt={callout.image.alt}
                          objectFit="cover"
                          sizes={{ fromM: '33vw' }}
                          loading="native-lazy"
                          aspectRatio={[4, 3]}
                          data-autoid={`${autoid}:grid:image:${idx}`}
                          extend={imageCSS({
                            mobileImages: showImagesOnMobile,
                          })}
                          imageDensity={imageDensity}
                        />
                      ))}
                    {video && (
                      <Video
                        autoplay
                        loop
                        poster={{
                          default: videoPoster || '',
                        }}
                        lazyLoadPoster={videoPoster ? true : false}
                        srcMp4={{
                          default: video || '',
                        }}
                        aspectRatio={getAspectRatio()}
                        data-autoid="calloutsContent:video"
                      />
                    )}
                    <Flex
                      extend={calloutTextCSS({
                        hasPadding: !showImagesOnMobile,
                      })}
                    >
                      {callout.title && (
                        <Text
                          as="h3"
                          variant={titleStyle}
                          subStyle={isTitleEmphasized ? 'emphasis' : 'standard'}
                          data-autoid={`${autoid}:grid:title:${idx}`}
                        >
                          {callout.title}
                        </Text>
                      )}

                      {callout.description && (
                        <>
                          <Spacer size={1} />
                          {popUpDisclaimerItem &&
                          popUpDisclaimerItem.description ? (
                            <PopUpDisclaimer
                              {...popUpDisclaimerItem}
                              trackLabel={`open PopUpDisclaimer | ${callout.title}`}
                            >
                              {(icon) => (
                                <Text
                                  variant={descriptionStyle}
                                  extend={descriptionCSS(blackBoldDescription)}
                                  data-autoid={`${autoid}:grid:description:${idx}`}
                                >
                                  {callout.description} {icon}
                                </Text>
                              )}
                            </PopUpDisclaimer>
                          ) : (
                            <Markdown_DEPRECATED_VCCUI
                              variant={descriptionStyle}
                              data-autoid={`${autoid}:grid:description:${idx}`}
                              extend={descriptionCSS(blackBoldDescription)}
                              markdown={callout.description}
                            />
                          )}
                        </>
                      )}

                      {callout.disclaimer && (
                        <Disclaimer
                          extend={disclaimerDesktopCSS(
                            displayImages,
                            stackOnMobile,
                          )}
                          text={callout.disclaimer}
                        />
                      )}
                    </Flex>

                    {callout.cta && callout.cta?.text && callout.cta?.href && (
                      <Flex
                        extend={linkCSS({ hasPadding: !showImagesOnMobile })}
                      >
                        {linkToButton ? (
                          <Block extend={buttonCSS}>
                            <Button
                              trackData={{ eventCategory }}
                              aria-label={callout.cta.title}
                              data-autoid="button"
                              variant="outline"
                              //extend={buttonCSS}
                              // @ts-ignore Check if this is correct value to track for ga4
                              trackEventAction={
                                trackingData?.eventCategory ===
                                'local page interaction'
                                  ? 'button|click'
                                  : 'click'
                              }
                              trackGA3={{ eventAction: 'click' }}
                              trackEventLabel={
                                trackingData?.eventCategory &&
                                !callout.ctaTrackLabel
                                  ? `${callout.cta.text} | ${callout.cta.href}`
                                  : callout.ctaTrackLabel
                              }
                              href={callout.cta.href}
                              {...(callout.cta.href.includes('#overlay-') && {
                                id: callout.cta.href.replace('#overlay-', ''),
                              })}
                              {...(callout.cta.target && {
                                target: callout.cta.target,
                              })}
                            >
                              {callout.cta.text.slice(0, 14) ||
                                callout.cta.href}
                            </Button>
                          </Block>
                        ) : (
                          <Link
                            className={classNameLink}
                            trackData={{ eventCategory }}
                            aria-label={callout.cta.title}
                            // @ts-ignore Check if this is correct value to track for ga4
                            trackEventAction={
                              trackingData?.eventCategory ===
                              'local page interaction'
                                ? 'link|click'
                                : 'click'
                            }
                            trackGA3={{ eventAction: 'click' }}
                            trackEventLabel={
                              trackingData?.eventCategory &&
                              !callout.ctaTrackLabel
                                ? `${callout.cta.text} | ${callout.cta.href}`
                                : callout.ctaTrackLabel
                            }
                            href={callout.cta.href}
                            {...(callout.cta.href.includes('#overlay-') && {
                              id: callout.cta.href.replace('#overlay-', ''),
                            })}
                            {...(callout.cta.target && {
                              target: callout.cta.target,
                            })}
                          >
                            {callout.cta.text || callout.cta.href}
                          </Link>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </AnimatedReveal>
              );
            })}
        </Flex>
      </ResponsiveGridHelper>
    </Flex>
  );
};

const contentCSS: ExtendCSS = {
  maxWidth: maxContentWidth12Columns,
};

const linkCSS =
  ({ hasPadding = false }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    paddingTop: `${2 * baselineGrid}px`,
    ...(hasPadding && {
      untilM: {
        width: `calc(100% - ${6 * baselineGrid}px)`,
        margin: '0 auto',
      },
    }),
  });

const buttonCSS: ExtendCSS = {
  width: '50%',
  untilL: {
    width: '180px',
  },
};

const calloutGridCSS =
  (
    contentDirectionOnDesktop: CalloutsContentProps['contentDirectionOnDesktop'] = 'horizontal',
    showAllCalloutItems = false,
  ): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    gap: baselineGrid * 4,
    flexDirection: 'column',
    flex: '0 0 auto',
    width: '100%',
    flexWrap: showAllCalloutItems ? 'wrap' : 'unset',
    fromM: {
      ...(contentDirectionOnDesktop === 'vertical' && {
        maxWidth: maxContentWidth12Columns / 2,
        marginTop: 4 * baselineGrid,
        marginRight: 'auto',
        marginLeft: 'auto',
      }),
      flexDirection:
        contentDirectionOnDesktop === 'horizontal' ? 'row' : 'column',
      gap: baselineGrid * 3,
    },
  });

const calloutItemCSS =
  ({
    isMobileFullWidth = false,
    constrainMaxWidth = true,
    showAllCalloutItems = false,
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    width: '100%',
    fromL: {
      flexBasis: showAllCalloutItems ? '31.00%' : 'unset',
    },
    ...(isMobileFullWidth && {
      untilM: {
        width: `calc(100% - ${6 * baselineGrid}px)`,
        margin: '0 auto',
      },
    }),
    onlyM: {
      maxWidth: '29%',
      flexBasis: showAllCalloutItems ? '30.00%' : 'unset',
    },
    ...(constrainMaxWidth && {
      fromM: {
        margin: 0,
        maxWidth: '33%',
        '&:nth-last-child(3n + 1)': {
          marginRight: 'auto',
        },
        '&:nth-child(3n + 1)': {
          marginLeft: 'auto',
        },
      },
    }),
  });

const disclaimerDesktopCSS =
  (displayImages?: boolean, stackOnMobile?: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    marginTop: 3 * baselineGrid,
    fromM: {
      textAlign: 'left',
      alignSelf: 'flex-start',
      display: 'flex',
    },
    ...(displayImages &&
      !stackOnMobile && {
        display: 'none',
      }),
  });

const imageCSS =
  ({ mobileImages = false }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    margin: `0 0 ${3 * baselineGrid}px 0`,
    ...(!mobileImages && {
      onlyS: {
        display: 'none',
      },
    }),
  });

const imageClickCSS = (imageClickable?: boolean): ExtendCSS => ({
  pointerEvents: imageClickable ? 'auto' : 'none',
});

const calloutTextCSS =
  ({ hasPadding = false }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    ...(hasPadding && {
      untilM: {
        width: `calc(100% - ${6 * baselineGrid}px)`,
        margin: '0 auto',
      },
    }),
  });

const descriptionCSS =
  (blackBoldDescription?: boolean): ExtendCSS =>
  ({ theme: { color } }) => ({
    ...(blackBoldDescription && {
      '& strong': {
        color: color.foreground.primary,
      },
    }),
    color: color.foreground.secondary,
  });

export default CalloutsContent;
