import React from 'react';
import { hasVideoContent } from '@vcc-www/video';
import { CalloutsContent } from '../callouts-content';
import { CalloutsOverlay, CalloutsOverlayProvider } from '../callouts-overlay';
import AnimatedReveal from '@vcc-www/animated-reveal';
import CalloutsProps from './Callouts.types';
import Media from './Media';
import Heading from './Heading';
import Ctas from './Ctas';
import Disclaimer from './Disclaimer';
import {
  CalloutDisclaimersCarousel,
  CalloutsCarousel,
  CarouselProvider,
} from './Carousels';
import { Container, ContentContainer } from './Containers';
import { ExtendCSS, Flex } from 'vcc-ui';

const Callouts: React.FC<React.PropsWithChildren<CalloutsProps>> = ({
  content: {
    heading,
    cta,
    landscapeVideoWebm,
    portraitVideoWebm,
    callouts,
    disclaimer,
    ctaLabel,
    landscapeImage,
    landscapeVideoMp4,
    portraitImage,
    portraitVideoMp4,
    subHeading,
    videos,
    ctaHref,
    ctaTrackingLabel,
  },
  paddedItemsOnDesktop,
  disclaimerAlignmentOnMobile = 'left',
  mediaWidthDesktop,
  headerStyle,
  showCalloutMediaInline,
  largePlayIcon,
  lazyLoadPoster,
  loop,
  loopVideo,
  autoplay,
  stackOnMobile,
  centeredPlayIcon,
  headerAlignment,
  headerPosition,
  headerPositionOnMobile,
  ctaVariant = 'outline',
  titleVariant,
  descriptionVariant,
  contentDirectionOnDesktop,
  SectionCoverComponent = React.Fragment,
  marginsOnMobile,
  isFullWidth,
  blackBoldDescription,
  trackingData,
  extendGridCSS,
  extendItemCSS,
  showAllCalloutItems,
  imageClickable,
  linkToButton,
}) => {
  const hasImage = Boolean(landscapeImage?.src || portraitImage?.src);
  const hasVideo = Boolean(
    hasVideoContent({ videos }) ||
      landscapeVideoMp4?.src ||
      landscapeVideoWebm?.src ||
      portraitVideoMp4?.src ||
      portraitVideoWebm?.src,
  );
  const hasMedia = hasImage || hasVideo;
  const displayCalloutMedia = !hasMedia && showCalloutMediaInline;
  const showMoreButton = callouts?.length > 3 && !ctaHref;
  return (
    <CalloutsOverlayProvider>
      {({ openOverlay }) => (
        <CarouselProvider>
          <AnimatedReveal>
            <SectionCoverComponent>
              <Container>
                <Heading
                  {...{
                    heading,
                    subHeading,
                    headerStyle,
                    showCalloutMediaInline,
                    headerAlignment,
                    headerPosition,
                    headerPositionOnMobile,
                    hasMedia,
                  }}
                />
              </Container>
              {displayCalloutMedia && !stackOnMobile && (
                <CalloutsCarousel
                  callouts={callouts}
                  {...{ showAllCalloutItems }}
                />
              )}
              <Container isMobileFullWidth isFullWidth={isFullWidth}>
                <AnimatedReveal stagger={2}>
                  <ContentContainer>
                    <Media
                      {...{
                        videos,
                        portraitImage,
                        portraitVideoMp4,
                        portraitVideoWebm,
                        landscapeImage,
                        landscapeVideoMp4,
                        landscapeVideoWebm,
                        mediaWidthDesktop,
                        largePlayIcon,
                        lazyLoadPoster,
                        loop,
                        loopVideo,
                        autoplay,
                        centeredPlayIcon,
                        marginsOnMobile,
                        hasVideo,
                        hasImage,
                      }}
                    />
                    <CalloutsContent
                      {...{ showAllCalloutItems }}
                      callouts={callouts}
                      autoid="callouts"
                      displayImages={displayCalloutMedia}
                      paddedItemsOnDesktop={paddedItemsOnDesktop}
                      showImagesOnMobile={stackOnMobile}
                      titleVariant={titleVariant}
                      descriptionVariant={descriptionVariant}
                      contentDirectionOnDesktop={contentDirectionOnDesktop}
                      blackBoldDescription={blackBoldDescription}
                      trackingData={trackingData}
                      extendGridCSS={extendGridCSS}
                      extendItemCSS={extendItemCSS}
                      imageClickable={imageClickable}
                      linkToButton={linkToButton}
                      stackOnMobile={stackOnMobile}
                    />
                    <Ctas
                      {...{
                        cta,
                        callouts,
                        ctaLabel,
                        ctaHref,
                        ctaTrackingLabel,
                        ctaVariant,
                        openOverlay,
                        showAllCalloutItems,
                      }}
                    />
                  </ContentContainer>
                </AnimatedReveal>
                {displayCalloutMedia && !stackOnMobile && (
                  <Flex extend={disclaimerCSS}>
                    <CalloutDisclaimersCarousel callouts={callouts} />
                  </Flex>
                )}
                <Disclaimer {...{ disclaimer, disclaimerAlignmentOnMobile }} />
              </Container>
              {showMoreButton && (
                <CalloutsOverlay
                  title={heading}
                  callouts={callouts}
                  disclaimer={disclaimer}
                />
              )}
            </SectionCoverComponent>
          </AnimatedReveal>
        </CarouselProvider>
      )}
    </CalloutsOverlayProvider>
  );
};

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  untilM: {
    paddingLeft: baselineGrid * 3,
    paddingRight: baselineGrid * 3,
  },
});

export default Callouts;
