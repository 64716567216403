import React from 'react';
import withSitecoreLayoutServiceAdapter from '@vcc-www/api/adapters/withSitecoreLayoutServiceAdapter';
import Callouts from '@vcc-editorial/callouts';
import CalloutsProps from '@vcc-editorial/callouts/src/Callouts.props';
import { useCalloutCtaInGox5MarketsFlag } from '@vcc-www/dotcom-feature-flags/flags/experiments/useCalloutCtaInGox5MarketsFlag';

const CalloutsWithAbTest: React.FC<
  CalloutsProps & { isABTestingEnabled?: string | undefined }
> = ({ isABTestingEnabled, ...props }) => {
  const variation = useCalloutCtaInGox5MarketsFlag({
    isABTestingEnabled,
  });

  if (isABTestingEnabled) {
    const { ctaHref, ctaLabel, ...contentWithoutCta } = props.content;
    return (
      <Callouts
        {...props}
        content={variation === 'A' ? props.content : contentWithoutCta}
      />
    );
  } else {
    return <Callouts {...props} />;
  }
};

/**
 * Wraps the component with a HOC that makes it ready to consume data from the Sitecore Layout Service.
 */
export default withSitecoreLayoutServiceAdapter(CalloutsWithAbTest);
